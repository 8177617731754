#tarteaucitronServices {
  height: auto !important;
}

.tarteaucitronBorder {
  height: 350px !important;
}

.tarteaucitronMainLine {
  border: 3px solid #333 !important;
}

.tarteaucitronIsDenied {
  border-color: #9C1A1A !important;
}

button.tarteaucitronDeny {
  background: #9C1A1A !important;
  margin-left: 2rem !important;
  color: #FFF !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-size: 16px !important;
  line-height: 1.2 !important;
  padding: 0 !important;
  text-decoration: none !important;
  position: relative !important;
  text-decoration: none !important;
  width: 200px !important;
  height: 38px !important;
}

.tarteaucitronIsAllowed {
  border-color: #1B870B !important;
}


body #tarteaucitronRoot div #tarteaucitronAlertBig {
  box-shadow: 0 0 50px #F7F9FD !important;
  padding: 50px 25px 25px 25px !important
}

body div #tarteaucitronRoot .tarteaucitronBeforeVisible:before {
  /* huge z-index to cover chat assistance*/
  z-index: 16000003;
}

div #tarteaucitronAlertBig:before {
  content: none !important;
}

.tarteaucitronAlertBigTop {
  background-color: #FFF !important;
  border: 3px solid #EFF4FE !important;
  max-width: 680px;
}

#tarteaucitronRoot span#tarteaucitronDisclaimerAlert {
  color: #252525 !important;
  font-size: 0.9rem !important;
}

#tarteaucitronRoot .tarteaucitronAllow {
  border-color: none !important;
  background: #0FA9C3 !important;
  color: #FFF !important;
  min-width: 200px !important;
  height: 38px !important;
  border-radius: 5px !important;
  padding: 0 10px !important
}

#tarteaucitronCloseAlert,
.tarteaucitronCloseAlert {
  background: #F4F6FC !important;
  color: #252525 !important;
  min-width: 200px !important;
  height: 38px !important;
  border-radius: 5px !important;
  padding: 0 10px !important;
}

#tarteaucitronRoot #tarteaucitronAllDenied2 {
  background: none !important;
  color: #777 !important;
  text-decoration: underline !important;
  border: none !important;
  position: absolute !important;
  top: 4%;
  right: 4%;
  font-size: 0.8rem !important;
}

#tarteaucitronAllDenied2 > span {
  display: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background-color: #F2F2F2 !important;
  border: none !important;
}

#tarteaucitron .tarteaucitronBorder {
  border: none !important;
  background: #F2F2F2 !important;
}

.tarteaucitronH1 {
  color: #000 !important;
}

#tarteaucitronRoot .tarteaucitronH2 {
  color: #000 !important;
}

#tarteaucitronInfo {
  color: #000 !important;
  background: #F2F2F2 !important;
}

.tarteaucitronTitle > button {
  color: #000 !important;
  background-color: #FFF !important;
}

.tarteaucitronTitle > button:hover {
  cursor: pointer;
}

#tarteaucitronRoot .tarteaucitronPlus:before {
  color: #000 !important;
}

.tarteaucitronBorder > ul > li {
  border-top: 1px solid #000 !important;
  border-bottom: 1px solid #000 !important;
  background: #FFF !important;
  margin-top: 2rem !important;
}

.tarteaucitronLine {
  background: white !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  width: 39% !important;
}

@media (max-width: 768px) {
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
    width: 100% !important;
  }

}
@media (max-width: 425px) {
   button.tarteaucitronDeny {
    width: auto !important;
    padding: 0 10px !important;
    margin-left: 0px !important;
   }

   #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk#tarteaucitronScrollbarAdjust {
    display: flex !important;
    flex-direction: column;
   }

   #tarteaucitronRoot #tarteaucitronAllAllowed {
    margin-left: 0 !important;
   }

   #tarteaucitronRoot .tarteaucitronDeny {
    margin-left: 0 !important;
   }

}
